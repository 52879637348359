import React from "react";

import Layout from "sections/Layout";
import TextContent from "@/components/TextContent";

const ReturnAndRefund = ({ page }) => {
  return (
    <Layout headerDarkColor={true}>
      {(commonData) => <TextContent data={page.content} />}
    </Layout>
  );
};

export default ReturnAndRefund;
